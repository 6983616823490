<template>
  <div>
    <!-- this form use for role table,user group table,group table, tag table , status table , project type -->
    <validation-observer ref="skillObserver">
      <b-sidebar
        id="addPrimarySkill"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Primary Skill
        </h3>
        <div class="line user-underline" />
        <!--Primary Skill name start-->
        <b-form-group
          label="Name"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required|min:3|max:30"
          >
            <b-form-input
              id="name"
              v-model="name"
              :state="errors.length > 0 ? false : null"
              placeholder="Skill Name"
              name="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Primary Skill name end-->

        <template #footer>
          <!--form footer-->
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <!-- :disabled="invalid || btnLoader" -->
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData"
            >
              Clear
            </button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'ProjectTypeFrom',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: null,
      id: null,
      btnLoader: false,
    }
  },
  methods: {
    /**
     *  clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.name = null
      this.$refs.skillObserver.reset()
    },
    /**
     * check the form validation
     * @returns if success true then call saveClientDtl Method
     */
    validationForm() {
      this.$refs.skillObserver.validate().then(success => {
        if (success) {
          this.savePrimarySkillDtl()
        }
      })
    },
    /**
     * Add & Update the primarySkillform details & call primarySkill listing in primarySkilltable
     * @emits skillList
     * @returns PrimarySkill detail add & update
     */
    async savePrimarySkillDtl() {
      this.btnLoader = true
      const input = {
        name: this.name,
      }
      let response = null
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `admin/master/primary-skill/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'admin/master/primary-skill/create',
          input,
          true,
        )
      }

      if (response && response.status === 200) {
        this.$root.$emit('bv::toggle::collapse', 'addPrimarySkill')
        this.$emit('skillList', true)
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = false
    },

    /**
     *  Clear form details
     */
    clearData() {
      this.name = null
      this.$refs.skillObserver.reset()
    },
  },
}
</script>

<style>
</style>
